import * as React from "react"
import Layout from "../components/layout"
import Bio from "../components/Bio"
import StartCustom from "../components/StartCustom"

const BioPage = () => (
  <Layout>
    <Bio />
    <StartCustom />
  </Layout>
)

export default BioPage